import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/contexts/appContext";
import { ListEvent } from "src/components/organisms/lists/listEvent/listEvent";
import { Page } from "../layouts/page";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { ListEventSkeleton } from "src/components/atoms/skeleton/listEventSkeleton";
import { EventItem } from "src/types/ApiItems";

export const PlusEvents = () => {
  const [events, setEvents] = useState<EventItem[] | null>(null);
  const { api, getHomeItemTitle } = useContext(AppContext);
  useEffect(() => {
    api.events.getAll().then((result) => {
      setEvents(result);
    });
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const events = await api.events.getAll();
  //     setEvents(events);
  //   };
  //   fetchData();
  // }, [api]);
  return (
    <Page>
      <PageHeader title={getHomeItemTitle("events")} back={routes.home} />
      <Container left small>
        {events ? <ListEvent events={events} /> : <ListEventSkeleton numberItem={3} />}
        {/* {events.length &&
        events.map((event, idx) => (
          <div className="events" key={idx}>
            {idx} - {event.title} - {ctx.isOnline ? "online" : "offline"}
          </div>
        ))}
      {!events.length && <div className="events">LOADING</div>} */}
      </Container>
    </Page>
  );
};
