import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";

type DateProps = {
  color?: "orange" | "";
};

export const Date: FunctionComponent<DateProps> = ({ children, ...modifiers }) => {
  if (children) {
    return <span className={bemModifier(modifiers, "date")}>{children}</span>;
  } else {
    return null;
  }
};
