import React, { FunctionComponent, useContext } from "react"; // importing FunctionComponent
import { getRoute, routes } from "src/AppRouter";
import { AppContext } from "src/contexts/appContext";
import { NewsEntreprise, OfferRH } from "src/types/ApiItems";
import { AppNewsType } from "src/types/dataTypes";
// import { NewsTypeLabel } from "src/helpers/enum";
import { List } from "src/components/organisms/lists/list/list";
import { ListItem } from "../../listItem/listItem";

type ListNewsProps = {
  news: Array<NewsEntreprise | OfferRH>;
  type: AppNewsType;
}
export const ListNews: FunctionComponent<ListNewsProps> = ({ news, type }) => {
  const { isRead } = useContext(AppContext);
  const routeByType: { [key in AppNewsType]: string | null } = {
    company: routes.newsCompanyDetail,
    dontMiss: routes.newsDontMissDetail,
    job: routes.jobDetail,
    information: routes.newsInformationDetail,
    organisation: routes.newsOrganisationDetail,
    restaurant: routes.newsRestaurantDetail,
    sport: routes.newsSportDetail,
    committee: routes.newsCommitteeDetail,
    delegation: routes.delegationSyndicaleDetail
  };
  const allOffers = news.length
    ? news.map((singleNew, index) => {
      const route = routeByType[type];
        return (
          <ListItem
            overtitle={singleNew.category ? singleNew.category : " "}
            title={singleNew.title}
            classModifier={type}
            imgId={singleNew.imageUrl}
            newLabel={singleNew.isImportant ? "important" : singleNew.isNew ? "new" : undefined}
            date={singleNew.publishDate ? singleNew.publishDate : undefined}
            link={route ? getRoute(route, { id: singleNew.id }) : undefined}
            externalLink={"externalLink" in singleNew ? singleNew.externalLink : undefined}
            key={index}
            tag={singleNew.division}
            unread={!isRead(type, singleNew.id) && singleNew.isNew}
            imgPlaceholder="/newsrh-placeholder.svg"
          />
        );
      })
    : "";
  return <List>{allOffers}</List>;
};

ListNews.defaultProps = {
  type: "company",
};
