import React, { FunctionComponent } from "react";
import { bemModifier } from "src/helpers/bemModifier";
export type ContainerProps = {
  small?: boolean;
  left?: boolean;
  yPadding?: boolean;
  xPadding?: boolean;
};
export const Container: FunctionComponent<ContainerProps> = ({
  children,
  xPadding = true,
  yPadding = true,
  ...modifers
}) => {
  return (
    <div
      className={bemModifier(
        { ...modifers, "x-padding-0": !xPadding, "y-padding-0": !yPadding },
        "container"
      )}
    >
      {children}
    </div>
  );
};

Container.defaultProps = {};
