import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Hero } from "src/components/molecules/hero/hero";
import { Text } from "src/components/atoms/text/text";
import { AppContext } from "src/contexts/appContext";
import { Page } from "src/layouts/page";
import { useParams } from "react-router-dom";
import { Icon } from "src/components/atoms/icon/icon";
import { Description } from "src/components/molecules/description/description";
import { routes } from "src/AppRouter";
import { Container } from "src/components/atoms/container/container";
import moment from "src/helpers/moment";
import { Wysiwyg } from "src/components/atoms/wysiwyg/wysiwyg";
import { DetailsSkeleton } from "src/components/atoms/skeleton/detailsSkeleton";
import { EventItem } from "src/types/ApiItems";

export const EventDetail = () => {
  const [event, setEvent] = useState<EventItem | null>(null);
  const [publishDate, setPublishDate] = useState<String>();
  const { api, setReadItem } = useContext(AppContext);
  const { id } = useParams();
  useEffect(() => {
    api.events.getById(id).then((result) => {
      setEvent(result);
      if (result.isNew) {
        setReadItem("event", id);
      }
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setPublishDate(moment(event?.publishStartDate).format("DD MMMM YYYY"));
  }, [event]);
  return (
    <Page pageClass="layout__detail eventDetail">
      {event ? (
        <>
          <Hero
            href={routes.events}
            title="Événements à venir"
            image={event.imageUrl}
            badge={event.isNew ? "new" : undefined}
          ></Hero>
          <Container>
            <Text type="subtitle">événements à venir</Text>

            <Text type="h1">{event.title}</Text>

            <div className="publish">
              <Icon name="time" />
              <Text type="p3" color="paleBrown">{`Publié le ${publishDate}`}</Text>
            </div>

            <div className="separator" />
            <Description
              title1="Date de début"
              desc1={moment(event.startDate).format("DD-MM-YYYY HH:MM")}
              title2="Date de fin"
              desc2={moment(event.endDate).format("DD-MM-YYYY HH:MM")}
              title3="Localisation"
              desc3={event.location}
              title4="Limite d'inscription"
              desc4={moment(event.endSignInDate).format("DD-MM-YYYY")}
            />
            <div className="separator" />

            <Wysiwyg content={event.description} />
          </Container>
        </>
      ) : (
        <DetailsSkeleton title="événements à venir" withImage withDescription />
      )}
    </Page>
  );
};
