import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Text } from "../../atoms/text/text";
import { BadgePill } from "../../atoms/badgePill/badgePill";
import { Icon } from "../../atoms/icon/icon";
import moment from "src/helpers/moment";
import { RouterLink } from "../../atoms/routerLink/routerLink";
import { getRoute, routes } from "src/AppRouter";

type EventProps = {
  /** Color of button */
  startDate: string;
  endDate?: string;
  title: string;
  notif?: string;
  location: string;
  limit: string;
  id: number;
  unread?: boolean;
};

export const Event: FunctionComponent<EventProps> = ({
  startDate,
  endDate,
  title,
  notif,
  location,
  limit,
  id,
  children,
  ...modifiers
}) => {
  const hour = moment(startDate).format("HH:mm");
  const dayStartDate = moment(startDate).format("D");
  const monthStartDate = moment(startDate).format("MMM");
  const dayEndDate = moment(endDate).format("D");
  const monthEndDate = moment(endDate).format("MMM");
  const secondDate = endDate
    ? moment(endDate).calendar() === moment(startDate).calendar()
      ? null
      : endDate
    : null;
  const limitCalculated = moment(limit) < moment() ? false : true;
  return (
    <li className={bemModifier(modifiers, "event")}>
      <div className={`img ${secondDate ? "twoDate" : ""}`}>
        {/* <div className="img twoDate"> */}
        <div>
          <span className="day">{dayStartDate}</span>
          <span className="month">{monthStartDate}</span>
        </div>
        {secondDate && <span className="hyphen"></span>}
        {secondDate && (
          <div>
            <span className="day">{dayEndDate}</span>
            <span className="month">{monthEndDate}</span>
          </div>
        )}
      </div>
      <div className="content">
        <div className="title">
          <Text type="h5">{title}</Text>
          <BadgePill color="primary">{notif}</BadgePill>
        </div>
        <div>
          <Icon name="time"></Icon>
          <Text type="p4">{hour}</Text>
          <Icon name="pin"></Icon>
          <Text type="p4">{location}</Text>
        </div>
        <div className="bottom">
          <Icon name="calendarClean" {...(!limitCalculated ? { color: "orange" } : {})}></Icon>
          <Text type="p4" {...(!limitCalculated ? { color: "orange" } : {})}>
            {limitCalculated
              ? `Limite d'inscription : ${moment(limit).format("DD.MM.YY")}`
              : "Inscriptions closes"}
          </Text>
        </div>
      </div>
      <RouterLink to={getRoute(routes.eventsDetail, { id })} transition="next"></RouterLink>
    </li>
  );
};
Event.defaultProps = {
  // This value is adopted when name prop is omitted.
};
