import React, { useContext } from "react";
import { Text } from "src/components/atoms/text/text";
import { Page } from "src/layouts/page";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { useState } from "react";
import { IError } from "src/types/IError";
import { useHistory } from "react-router-dom";
import { Textarea } from "src/components/atoms/textarea/textarea";
import { Button } from "src/components/atoms/button/button";
import { AppContext } from "src/contexts/appContext";
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
import { OfflineLock } from "src/components/molecules/offlineLock/offlineLock";

export const UpgradeAdvice = () => {
  const [comm, setComm] = useState<string>("");
  const [error, setError] = useState<IError>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { api, userInfo, toast, isOnline, getHomeItemTitle } = useContext(AppContext);
  const onSubmit = (): void => {
    setError(undefined);
    if (!comm || comm === "") {
      setError({
        field: "comm",
        message: "Remplissez ce champs afin de nous faire part de votre avis",
      });
    } else {
      setLoading(true);
      api.user
        .sendSuggestion({ message: comm, email: userInfo!.email })
        .then(() => {
          setLoading(false);
          history.push(routes.upgradeAdviceConfirmation);
        })
        .catch(() => {
          setLoading(false);
          toast.createUnknkowError();
        });
    }
  };
  return (
    <Page pageClass="upgradeAdvice">
      {!isOnline && <OfflineLock />}
      <PageHeader back={routes.home} title={getHomeItemTitle("upgradeAdvice")} />
      <Container>
        <Text type="h2">Faites-nous part de vos commentaires et suggestions pour améliorer l'application.</Text>
        <Textarea
          name="Commentaire"
          value={comm}
          setValue={setComm}
          error={error?.field === "comm"}
        />
        {error?.field === "comm" && (
          <Text type="p3" color="orange">
            {error.message}u
          </Text>
        )}
        <Button large onClick={onSubmit}>
          Envoyer
        </Button>
        <LoadingScreen show={loading} message="Envoi en cours" backdrop />
      </Container>
    </Page>
  );
};
