import { AnnounceCategory, AnnounceStatus, NewsType } from "src/types/ApiItems";

export const NewsTypeValue = {
  NONE: 0,
  ENTREPRISE: 1,
  RH: 2,
  OFFER: 3,
};
export const NewsTypeLabel: { [key in NewsType]?: string } = {
  0: undefined,
  1: "Enterprise",
  2: "RH",
  3: "Offer",
};

export const SmallAnnounceCategoryLabel: { [key in AnnounceCategory]?: string } = {
  0: undefined,
  1: "Je vends",
  2: "Je cherche",
};

export const SmallAnnounceStatusEnum: { [key in AnnounceStatus]?: string } = {
  0: undefined,
  1: "Published",
  2: "Awaiting",
  3: "Refused",
  4: "Expired",
};

export enum ErrorCodes {
  UNKNOWN_ERROR_SCREEN = 1000,
  UNKNOW_INITIAL_LOAD_FAIL = 1001,
  USER_INFO_FAIL = 1002,
  STRING_FAIL = 1003,
  HOME_ITEMS_FAIL = 1004,
  UNKNOWN_ERROR_ROUTER = 2000,
  BROWSER_UNKNOWN_ERROR = 3000,
  BROWSER_CONNECTION_ABORTED = 3001,
  NETWORK_UNKNOWN_ERROR = 4000,
  NETWORK_ERROR_500 = 4001,
  NETWORK_ERROR_404 = 4002,
  PROMISE_REJECTION_UNKNOWN = 5000,
  PROMISE_REJECTION_NETWORK_ERROR = 5001,
  API_BAD_RESPONSE_FORMAT = 6001,
  AUTH_UNKNOWN_ERROR = 7000
}

export const CommonNetworkCode = {
  CONNECTION_ABORTED: "ECONNABORTED",
};
export const CommonErrorMessage = {
  NETWORK_ERROR: "Network Error",
};

export const errorByResponseStatus = {
  404: ErrorCodes.NETWORK_ERROR_404,
  500: ErrorCodes.NETWORK_ERROR_500,
};
export const getErrorByStatus = (status: number) => {
  return errorByResponseStatus[status] || ErrorCodes.NETWORK_UNKNOWN_ERROR;
};
