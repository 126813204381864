import React, { FunctionComponent } from "react"; // importing FunctionComponent

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper.scss";

import "swiper/components/navigation/navigation.scss";
import { Image } from "../../atoms/image/image";
import { StoredImage } from "src/types/ApiItems";
import { Hero } from "../hero/hero";

type HeroSliderProps = {
  /** Color of button */
  images?: StoredImage[];
  date?: string;
  title?: string;
  badge?: string;
  href: string;
  color?: "white" | "paleBrown";
};
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
export const HeroSlider: FunctionComponent<HeroSliderProps> = ({
  title,
  date,
  badge,
  href,
  color,
}) => {
  return (
    <Hero title={title} date={date} badge={badge} href={href} className="heroSlider">
      <Swiper slidesPerView={1} navigation>
        {/* {images?.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="img">
                <Image id={image.id} />
              </div>
            </SwiperSlide>
          );
        })} */}
        <SwiperSlide>
          <div className="img">
            <Image url="http://unsplash.it/350/275?random&gravity=center" contain />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img">
            <Image url="http://unsplash.it/350/275?random&gravity=center" contain />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img">
            <Image url="http://unsplash.it/350/275?random&gravity=center" contain />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img">
            <Image url="http://unsplash.it/350/275?random&gravity=center" contain />
          </div>
        </SwiperSlide>
      </Swiper>
    </Hero>
  );
};
HeroSlider.defaultProps = {
  // This value is adopted when name prop is omitted.
  color: "paleBrown",
};
