import { FetchApi } from "./FetchApi";

export class ImageApi extends FetchApi {
  async getById(id, params) {
    const blob = await this.get<any>(`/images/blobv2/${id}${this.objectToQuery(params)}`, {
      defaultErrorCatch: false,
      addionnalAxiosOptions: {
        responseType: "blob",
      },
      cache: false,
    });
    return this.blobToDataUrl(blob);
  }
  blobToDataUrl(blob): Promise<string> {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        var imageDataUrl = reader.result;
        if (imageDataUrl) {
          resolve(imageDataUrl.toString());
        } else {
          resolve("");
        }
      };
    });
  }
}
