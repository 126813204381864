import { EventsApi } from "./EventsApi";
import { MenuApi } from "./MenuApi";
import { SmallAnnouncesApi } from "./SmallAnnouncesApi";
import axios, { AxiosInstance, AxiosError } from "axios";
import { StaticApi } from "./StaticApi";
import { NewsApi } from "./NewsApi";
import { JobsApi } from "./JobsApi";
import { UserApi } from "./UserApi";
import { ImageApi } from "./ImageApi";
import { DocumentReperesApi } from "./DocumentReperesApi";

export class PatekApi {
  news: NewsApi;
  jobs: JobsApi;
  events: EventsApi;
  menus: MenuApi;
  smallAnnounces: SmallAnnouncesApi;
  axios: AxiosInstance;
  axiosOptions: any;
  static: StaticApi;
  user: UserApi;
  image: ImageApi;
  documentReperes: DocumentReperesApi;
  private errorHanlders: { (data?: AxiosError): void }[] = [];
  constructor() {
    this.axios = axios.create();
    this.axiosOptions = {};
    this.jobs = new JobsApi((data) => this.errorCallback(data), this.axiosOptions);
    this.events = new EventsApi((data) => this.errorCallback(data), this.axiosOptions);
    this.news = new NewsApi((data) => this.errorCallback(data), this.axiosOptions);
    this.menus = new MenuApi((data) => this.errorCallback(data), this.axiosOptions);
    this.static = new StaticApi((data) => this.errorCallback(data), this.axiosOptions);
    this.user = new UserApi((data) => this.errorCallback(data), this.axiosOptions);
    this.image = new ImageApi((data) => this.errorCallback(data), this.axiosOptions);
    this.documentReperes = new DocumentReperesApi(
      (data) => this.errorCallback(data),
      this.axiosOptions
    );
    this.smallAnnounces = new SmallAnnouncesApi(
      (data) => this.errorCallback(data),
      this.axiosOptions
    );
    this.errorHanlders = [];
  }
  setToken(token) {
    this.axiosOptions["headers"] = { Authorization: "Bearer " + token };
  }
  async checkToken(token) {
    const { REACT_APP_OAUTH_INTROSPECT_URL, REACT_APP_OAUTH_CLIENT_ID } = process.env;
    const message = `client_id=${REACT_APP_OAUTH_CLIENT_ID}&token=${token}`;
    const response = await this.axios.post(REACT_APP_OAUTH_INTROSPECT_URL!, message, {
      headers: { "Content-Type": "text/plain" },
    });
    if (response.data && response.data.active) {
      return true;
    }
    return;
  }
  onError(handler) {
    this.errorHanlders.push(handler);
  }
  errorCallback(e: AxiosError) {
    this.errorHanlders.slice(0).forEach((handler) => handler(e));
  }
}
