import React, { FunctionComponent, useContext, useEffect, useRef, useState } from "react"; // importing FunctionComponent
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
import { AppContext } from "src/contexts/appContext";
import { bemModifier } from "../../../helpers/bemModifier";
import { download } from "../../../helpers/downloadFiles";

type WysiwygProps = {
  content: string | null;
  fontStyle?: "serif" | "sans-serif";
};

// type ErrorFromCall = {
//   message: string;
//   errorMessage: string;
//   maxFileSize: number;
//   unitMeasure: string;
// };

export const Wysiwyg: FunctionComponent<WysiwygProps> = ({ content, children, ...modifiers }) => {
  const { token } = useContext(AppContext);
  const wysiwygEl = useRef<any>();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (wysiwygEl.current) {
      const wysiwygElement: any = wysiwygEl.current;
      const regExURLToPatekFiles = /https?:\/\/appemp[-a-zA-Z0-9@:%._+~#=]{0,12}\.patek\.[a-zA-Z0-9()]{1,6}\b\/api\/files\/([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
      const regExURLToDisable = /(.)*(.aspx|.js|.html)/;
      const allLinks: any[] = wysiwygElement.getElementsByTagName("a");
      const linkToPatekFiles = Array.from(allLinks).filter((link) => {
        if (regExURLToPatekFiles.test(link.href)) {
          return true;
        } else {
          return false;
        }
      });
      const linkToDisable = Array.from(allLinks).filter((link) => {
        if (regExURLToDisable.test(link.href)) {
          return true;
        } else {
          return false;
        }
      });
      linkToPatekFiles?.forEach((item) => {
        item.addEventListener("click", (event) => {
          event.preventDefault();
          download(item.href, token, setShowModal);
        });
      });
      linkToDisable?.forEach((item) => {
        item.addEventListener("click", (event) => {
          event.preventDefault();
          alert("Warning: link not allowed");
        });
      });
    }
  }, [wysiwygEl, token]);

  if (content) {
    const mycontent = { __html: content };
    return (
      <div>
        <div
          ref={wysiwygEl}
          className={bemModifier(modifiers, "wysiwyg")}
          dangerouslySetInnerHTML={mycontent}
        />
        <LoadingScreen show={showModal} message="Téléchargement des fichiers en cours" backdrop />
      </div>
    );
  } else {
    return null;
  }
};

Wysiwyg.defaultProps = {
  fontStyle: "serif",
};
