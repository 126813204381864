import React, { FunctionComponent, useContext, useState } from "react";
import { Image } from "src/components/atoms/image/image";
import { Button } from "src/components/atoms/button/button";
import { Wysiwyg } from "../../atoms/wysiwyg/wysiwyg";
import { NewsBody as NewsBodyType } from "src/types/ApiItems";
import { AppContext } from "src/contexts/appContext";
import { download } from "../../../helpers/downloadFiles";
import { LoadingScreen } from "../loadingScreen/loadingScreen";
import { AppNewsType } from "src/types/dataTypes";

type NewsBodyProps = {
  /** Color of button */
  actu: NewsBodyType;
  type: AppNewsType;
};

export const NewsContent: FunctionComponent<NewsBodyProps> = ({ actu, type, children }) => {
  const { token } = useContext(AppContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  let bodyAction;
  const regExURLToPatekFiles = /https?:\/\/appemp[-a-zA-Z0-9@:%._+~#=]{0,12}\.patek\.[a-zA-Z0-9()]{1,6}\b\/api\/files\/([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
  const regExURLToDisable = /(.)*(.aspx|.js|.html)/;
  if (actu.newsBodyAction) {
    if (regExURLToPatekFiles.test(actu.newsBodyAction.url)) {
      bodyAction = (
        <Button
          onClick={() => download(actu.newsBodyAction?.url, token, setShowModal)}
          large
          outline
          color="secondary"
        >
          {actu.newsBodyAction?.buttonName}
        </Button>
      );
    } else if (regExURLToDisable.test(actu.newsBodyAction.url)) {
      bodyAction = (
        <Button large outline color="secondary" ahref={"#"}>
          Warning: link not allowed
        </Button>
      );
    } else {
      bodyAction = (
        <Button large outline color="secondary" ahref={actu.newsBodyAction?.url} target="_blank">
          {actu.newsBodyAction?.buttonName}
        </Button>
      );
    }
  }
  return (
    <div className="newsBody">
      {actu.newsBodyText && (
        <div className="newsText">
          <Wysiwyg content={actu.newsBodyText} />
        </div>
      )}
      {actu.newsBodyImage && (
        <div className="newsImg">
          <Image
            id={actu.newsBodyImage}
            hidePlaceholder
            // TODO : Revoir le fonctionnement de l'image pour corriger
            // 10000 pour type orga car incompréhension du fonctionnement du resize
            // après avoir essayé des tailles cohérente qui ne fonctionnaient pas
            width={10000}
            height={10000}
          />
        </div>
      )}
      {actu.newsBodyAction && bodyAction}
      <LoadingScreen show={showModal} message="Téléchargement des fichiers en cours" backdrop />
    </div>
  );
};
