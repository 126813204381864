import React, { useContext } from "react";
import { Page } from "src/layouts/page";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { ContactBox } from "src/components/molecules/contactBox/contactBox";
import { AppContext } from "src/contexts/appContext";

export const TrustPerson = () => {
  const { strings } = useContext(AppContext);
  return (
    <Page pageClass="trustPerson">
      <PageHeader title={strings!.pages.trustPerson.pageTitle} back={routes.health} />
      <Container>
        {strings?.pages.trustPerson.contacts.map((contact) => {
          if (contact.type === "internal") {
            return (
              <ContactBox
                title={contact.title}
                btnText={contact.telLabel}
                btnIcon="phone"
                secondaryBtnText={contact.emailLabel}
                secondaryBtnIcon="mail"
                href={contact.telContent}
                secondaryHref={contact.emailContent}
              />
            );
          } else if (contact.type === "external") {
            return (
              <ContactBox
                title={contact.title}
                subtitle={contact.subtitle}
                btnText={contact.telLabel}
                btnIcon="phone"
                secondaryBtnText={contact.emailLabel}
                secondaryBtnIcon="mail"
                href={contact.telContent}
                secondaryHref={contact.emailContent}
              />
            );
          }
        })}
      </Container>
    </Page>
  );
};
