import React, { FunctionComponent } from "react";
import { BadgeNotification } from "../../atoms/badgeNotification/badgeNotification";
import { Button } from "../../atoms/button/button";
import { IconNames } from "../../atoms/icon/icon";
import { Text } from "../../atoms/text/text";
export type ContactBoxProps = {
  link?: string;
  href?: string;
  badge?: number;
  title?: string;
  description?: string;
  subtitle?: string;
  btnIcon?: IconNames;
  btnText?: string;
  secondaryBtnText?: string;
  secondaryBtnIcon?: IconNames;
  secondaryLink?: string;
  secondaryHref?: string;
};
export const ContactBox: FunctionComponent<ContactBoxProps> = ({
  badge,
  title,
  subtitle,
  description,
  btnText,
  btnIcon,
  secondaryBtnText,
  secondaryBtnIcon,
  link,
  href,
  secondaryLink,
  secondaryHref,
}) => {
  return (
    <div className="contact-box">
      {badge && (
        <div className="contact-box__badge">
          <BadgeNotification large>{badge}</BadgeNotification>
        </div>
      )}
      {title && (
        <div className="contact-box__title">
          <Text type="h4">{title}</Text>
        </div>
      )}
      {subtitle && (
        <div className="contact-box__subtitle">
          <Text type="subtitle">{subtitle}</Text>
        </div>
      )}
      {description && (
        <div className="contact-box__description">
          <Text type="p3" center>
            {description}
          </Text>
        </div>
      )}
      {btnText && (
        <div className="contact-box__btn">
          <Button icon={btnIcon} color="secondary" link={link} ahref={href}>
            {btnText}
          </Button>
        </div>
      )}
      {secondaryBtnText && (
        <div className="contact-box__btn">
          <Button icon={secondaryBtnIcon} color="secondary" link={secondaryLink} ahref={secondaryHref}>
            {secondaryBtnText}
          </Button>
        </div>
      )}
    </div>
  );
};
