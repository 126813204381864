import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";

export const Badge: FunctionComponent = ({ children, ...modifiers }) => {
  if (children) {
    return <span className={bemModifier(modifiers, "badge")}>{children}</span>;
  } else {
    return null;
  }
};
