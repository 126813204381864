import React, { useContext } from "react";
import { Page } from "src/layouts/page";
import { Text } from "src/components/atoms/text/text";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { ContactBox } from "src/components/molecules/contactBox/contactBox";
import { AppContext } from "src/contexts/appContext";

export const HealthAdvice = () => {
  const { strings } = useContext(AppContext);
  return (
    <Page pageClass="healthAdvice">
      <PageHeader title={strings!.pages.healthAdvice.pageTitle} back={routes.health} />
      <Container>
        <Text type="h5">{strings?.pages.healthAdvice.pageSubtitle}</Text>
        <ContactBox
          title={strings?.contact.internationalSos.title}
          subtitle={strings?.contact.internationalSos.subtitle}
          btnText={strings?.contact.internationalSos.phoneLabel}
          href={`tel:${strings?.contact.internationalSos.phoneNumber}`}
          btnIcon="phone"
        />
        <ContactBox
          title={strings?.contact.swica.title}
          subtitle={strings?.contact.swica.subtitle}
          btnText={strings?.contact.swica.phoneLabel}
          href={`tel:${strings?.contact.swica.phoneNumber}`}
          btnIcon="phone"
        />
        <Text type="p3">{strings?.pages.healthAdvice.note}</Text>
        <div className="assurance">
          <Text type="p3">– {strings?.pages.healthAdvice.insurance1}</Text>
          <Text type="p3">– {strings?.pages.healthAdvice.insurance2}</Text>
        </div>
      </Container>
    </Page>
  );
};
