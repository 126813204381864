import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { RequestAuthorizationCode } from "react-oauth2-auth-code-flow";
import { AppContext } from "src/contexts/appContext";
import { oauthClient } from "src/oauthClient";
import { AuthorizationCodeCallback } from "react-oauth2-auth-code-flow";
import { useLocation } from "react-router-dom";
import { isRoute, routes } from "src/AppRouter";
import { localForageKeys } from "src/helpers/localForageKey";
import { cookies } from "src/helpers/miscHelpers";
import { ErrorScreen } from "src/components/molecules/errorScreen/errorScreen";
import { ErrorCodes } from "src/helpers/enum";
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
export type AuthPageProps = {};
export const AuthPage: FunctionComponent<AuthPageProps> = () => {
  const { setToken, api, toast } = useContext(AppContext);
  const [tokenIsChecked, setTokenIsChecked] = useState<boolean>(false);

  const location = useLocation();

  const isCallback = isRoute(routes.oAuthCallback, location.pathname);

  const handleError = (error) => {
    if(error){
      console.error("An error occurred");
      console.error(error.message);
    }
    toast.error("Erreur lors du login veuillez recharger la page", 90000);
  };

  useEffect(() => {
    const getToken = async () => {
      if (process.env.REACT_APP_OAUTH_FORCE_TOKEN) {
        setToken(process.env.REACT_APP_OAUTH_FORCE_TOKEN);
        api.setToken(process.env.REACT_APP_OAUTH_FORCE_TOKEN);
        return;
      }
      const token = cookies.get<string>(localForageKeys.accessToken);
      if (token) {
        const isValid = navigator.onLine ? await api.checkToken(token) : true;
        if (isValid) {
          setToken(token);
          api.setToken(token);
          return;
        }
      }

      setToken("");
      setTokenIsChecked(true);
    };

    getToken();
  }, []); // eslint-disable-line

  const handleSuccess = async (responseToken, { response, state }) => {
    setToken(responseToken.accessToken);
    window.location.replace("/");
  };



  const redirectToLogin = (url) => {
    window.location.href = url;
  };
  const errorInUrl = useMemo(()=>{
    if(!window || !window.location.href){
      return ""
    }
    const url = new URL(window.location.href);
    return url.searchParams.get("error");
  }, [])
  return (
    <div>
      {!isCallback && tokenIsChecked && (
        <>
          <RequestAuthorizationCode
            oauthClient={oauthClient}
            state={{ from: "/settings" }}
            render={({ url }) => redirectToLogin(url)}
          />
        </>
      )}
      {isCallback && (
        <AuthorizationCodeCallback
          oauthClient={oauthClient}
          onAuthSuccess={handleSuccess}
          onAuthError={handleError}
          render={({ processing, state, error }) => (
            <div>
              {processing && <LoadingScreen show={true} backdrop={true}>Authorizing now...</LoadingScreen>}
              {error && <ErrorScreen info={error.message + " "+ errorInUrl} code={ErrorCodes.AUTH_UNKNOWN_ERROR} homeredirect={true}>
                Erreur lors de l'authentification
                </ErrorScreen>}
            </div>
          )}
        />
      )}
    </div>
  );
};
