import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Icon, IconNames } from "../../atoms/icon/icon";
import { Text } from "../../atoms/text/text";
import { BadgeNotification } from "../../atoms/badgeNotification/badgeNotification";
import { RouterLink } from "../../atoms/routerLink/routerLink";

type ButtonIconProps = {
  notif?: number;
  large?: boolean;
  icon: IconNames;
  link: string;
  isExternal?: boolean;
};

export const ButtonIcon: FunctionComponent<ButtonIconProps> = ({
  notif,
  large,
  icon,
  link,
  isExternal,
  children,
  ...modifiers
}) => {
  const linkContent = !large ? (
    <>
      <div className="iconPos">
        {notif && <BadgeNotification>{notif}</BadgeNotification>}
        <div className="iconBack">
          <Icon name={icon}></Icon>
        </div>
      </div>
      <Text type="p3" center>
        {children}
      </Text>
    </>
  ) : (
    <>
      <div className="iconPos">
        <div className="iconBack">
          <Icon name={icon}></Icon>
        </div>
      </div>
      <Text type="h4">{children}</Text>
      <div className="rightArrow">
        <Icon name="chevronRight"></Icon>
      </div>
    </>
  );

  const className = large
    ? bemModifier(modifiers, "buttonIcon large")
    : bemModifier(modifiers, "buttonIcon");

  if (!isExternal) {
    return (
      <RouterLink to={link} className={className}>
        {linkContent}
      </RouterLink>
    );
  } else {
    return (
      <a href={link} target="_blank" rel="noreferrer" className={className}>
        {linkContent}
      </a>
    );
  }
};
ButtonIcon.defaultProps = {
  isExternal: false,
};
