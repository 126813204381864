import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "src/components/atoms/container/container";
import { Text } from "src/components/atoms/text/text";
import { DocumentHeader } from "src/components/molecules/documentHeader/documentHeader";
import { ListDocumentItem } from "src/components/organisms/lists/listDocumentItem/listDocumentItem";
import { AppContext } from "src/contexts/appContext";
import { Page } from "src/layouts/page";
import { File } from "src/types/ApiItems";

export type DocumentBaseProps = {
  back: string;
  loading?: boolean
};
export const DocumentBase: React.FunctionComponent<DocumentBaseProps> = ({ loading, children, back }) => {
  const { api } = React.useContext(AppContext);

  const [resultFolder, setResultFolder] = React.useState<File | any>();
  const [resultFiles, setResultFiles] = React.useState<File | any>();
  const [query, setQuery] = React.useState("");
  const location = useLocation();

  useEffect(() => {
    setQuery("");
  }, [location]);

  const setSearchResult = useCallback(
    (result: File[]) => {
      if (result) {
        api.documentReperes.getAll().then((allFiles) => {
          setResultFolder(
            result
              .filter((f) => f.isAFolder)
              .map((folder) => ({
                ...folder,
                children: [],
                fileCount: allFiles.filter((file) => {
                  return file.parentId === folder.id;
                }).length,
              }))
          );
          setResultFiles(result.filter((item) => !item.isAFolder));
        });
      }
    },
    [api]
  );

  useEffect(() => {
    let timeout;
    if (query.length >= 4) {
      timeout = setTimeout(() => {
        api.documentReperes.getSearchResult(query).then((result) => {
          setSearchResult(result);
        });
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [api.documentReperes, query, setSearchResult]);

  return (
    <Page>
      <DocumentHeader
        title="Documents Importants"
        searchLabel="Recherche..."
        value={query}
        loading={loading}
        onChange={(value) => setQuery(value)}
        back={back}
      />
      {query.length >= 4 ? (
        <div className="document-header__results">
          <Container>
            {resultFolder && (
              <section style={{ margin: ".85rem 0 1.5rem" }}>
                <Text type="h2">{resultFolder.length} résultats de dossier</Text>
              </section>
            )}
            {resultFolder?.map((item, index) => {
              return <ListDocumentItem key={index} {...item} />;
            })}
            {resultFiles && (
              <section style={{ margin: "1.5rem 0" }}>
                <Text type="h2">{resultFiles.length} résultat de fichier</Text>
              </section>
            )}
            {resultFiles?.map((item, index) => {
              return <ListDocumentItem key={index} {...item} />;
            })}
          </Container>
        </div>
      ) : (
        <>{children}</>
      )}
    </Page>
  );
};
