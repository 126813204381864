import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { ReactComponent as Accident } from "src/assets/icons/accident.svg";
import { ReactComponent as Actus } from "src/assets/icons/actus.svg";
import { ReactComponent as Announce } from "src/assets/icons/announce.svg";
import { ReactComponent as ArrowLeft } from "src/assets/icons/arrow_left.svg";
import { ReactComponent as Document } from "src/assets/icons/document.svg";
import { ReactComponent as DocumentClose } from "src/assets/icons/document_close.svg";
import { ReactComponent as DocumentOpen } from "src/assets/icons/document_open.svg";
import { ReactComponent as Search } from "src/assets/icons/search.svg";
import { ReactComponent as Xlsx } from "src/assets/icons/xlsx.svg";
import { ReactComponent as Pptx } from "src/assets/icons/pptx.svg";
import { ReactComponent as Pdfx } from "src/assets/icons/pdfx.svg";
import { ReactComponent as Calendar } from "src/assets/icons/calendar.svg";
import { ReactComponent as CalendarClean } from "src/assets/icons/event_12px.svg";
import { ReactComponent as Car } from "src/assets/icons/car.svg";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron_down.svg";
import { ReactComponent as ChevronLeft } from "src/assets/icons/chevron_left.svg";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import { ReactComponent as Close } from "src/assets/icons/close_12px.svg";
import { ReactComponent as Contact } from "src/assets/icons/contact.svg";
import { ReactComponent as Favorite } from "src/assets/icons/favorite.svg";
import { ReactComponent as Files } from "src/assets/icons/files.svg";
import { ReactComponent as Food } from "src/assets/icons/food.svg";
import { ReactComponent as FoodColor } from "src/assets/icons/food_Color.svg";
import { ReactComponent as Health } from "src/assets/icons/health.svg";
import { ReactComponent as HomeIcon } from "src/assets/icons/home.svg";
import { ReactComponent as Info } from "src/assets/icons/info.svg";
import { ReactComponent as Mail } from "src/assets/icons/mail.svg";
import { ReactComponent as Message } from "src/assets/icons/message.svg";
import { ReactComponent as Note } from "src/assets/icons/note.svg";
import { ReactComponent as Orga } from "src/assets/icons/orga.svg";
import { ReactComponent as Phone } from "src/assets/icons/phone.svg";
import { ReactComponent as Pin } from "src/assets/icons/pin_12px.svg";
import { ReactComponent as Ping } from "src/assets/icons/ping.svg";
import { ReactComponent as Plus } from "src/assets/icons/plus.svg";
import { ReactComponent as SearchJob } from "src/assets/icons/searchJob.svg";
import { ReactComponent as Sport } from "src/assets/icons/sport.svg";
import { ReactComponent as Time } from "src/assets/icons/time_12px.svg";
import { ReactComponent as Docx } from "src/assets/icons/docx.svg";
import { ReactComponent as RestoClose } from "src/assets/icons/restoClose.svg";
import { ReactComponent as Watch } from "src/assets/icons/watch.svg";
import { ReactComponent as Handshake } from "src/assets/icons/handshake.svg";
// export type IconNames = 'accident' | 'actus' | 'announce' | 'arrow_left' | 'calendar' | 'calendarClean' | 'car' | 'chevronDown' | 'chevronLeft' | 'chevronRight' | 'close' | 'contact' | 'favorite' | 'files' | 'food' | 'health' | 'mail' | 'message' | 'note' | 'orga' | 'phone' | 'pin' | 'plus' | 'searchJob' | 'sport' | 'time' | 'restoClose' | 'watch'

export const AllIconNames = [
  "accident",
  "actus",
  "document",
  "documentClose",
  "documentOpen",
  "announce",
  "arrow_left",
  "calendar",
  "search",
  "calendarClean",
  "xlsx",
  "car",
  "pptx",
  "chevronDown",
  "pdfx",
  "pdf",
  "chevronLeft",
  "chevronRight",
  "close",
  "contact",
  "favorite",
  "files",
  "food",
  "foodColor",
  "handshake",
  "health",
  "home",
  "info",
  "mail",
  "message",
  "note",
  "orga",
  "phone",
  "pin",
  "ping",
  "plus",
  "searchJob",
  "sport",
  "time",
  "docx",
  "docm",
  "restoClose",
  "watch",
] as const; // TS 3.4
type IconsTuple = typeof AllIconNames; // readonly
export type IconNames = IconsTuple[number]; // union type

type IconProps = {
  /** Color of button */
  color?: "brownishGrey" | "white" | "paleBrown" | "orange";
  name: IconNames;
  className?: string;
};

const icons: { [key in IconNames]: JSX.Element } = {
  accident: <Accident />,
  actus: <Actus />,
  document: <Document />,
  documentClose: <DocumentClose />,
  documentOpen: <DocumentOpen />,
  announce: <Announce />,
  arrow_left: <ArrowLeft />,
  calendar: <Calendar />,
  calendarClean: <CalendarClean />,
  car: <Car />,
  chevronDown: <ChevronDown />,
  chevronLeft: <ChevronLeft />,
  chevronRight: <ChevronRight />,
  close: <Close />,
  contact: <Contact />,
  pptx: <Pptx />,
  favorite: <Favorite />,
  search: <Search />,
  xlsx: <Xlsx />,
  files: <Files />,
  pdfx: <Pdfx />,
  pdf: <Pdfx />,
  food: <Food />,
  foodColor: <FoodColor />,
  handshake: <Handshake />,
  health: <Health />,
  home: <HomeIcon />,
  info: <Info />,
  mail: <Mail />,
  message: <Message />,
  note: <Note />,
  orga: <Orga />,
  phone: <Phone />,
  pin: <Pin />,
  ping: <Ping />,
  plus: <Plus />,
  searchJob: <SearchJob />,
  sport: <Sport />,
  time: <Time />,
  docx: <Docx />,
  docm: <Docx />,
  restoClose: <RestoClose />,
  watch: <Watch />,
};

export const Icon: FunctionComponent<IconProps> = ({ className, name, ...modifiers }) => {
  return <div className={`${className} ${bemModifier(modifiers, "icon")}`}>{icons[name]}</div>;
};
Icon.defaultProps = {
  color: "brownishGrey", // This value is adopted when name prop is omitted.
};
