import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Badge } from "../../atoms/badge/badge";
import { Image } from "../../atoms/image/image";
import { Text } from "../../atoms/text/text";
import food_Color from "src/assets/icons/food_Color.svg";

type RecipeProps = {
  /** Color of button */
  type: string;
  imgUrl?: string;
  imgId?: string;
  title: string;
  description?: string;
  price?: string;
};

export const Recipe: FunctionComponent<RecipeProps> = ({
  type,
  imgUrl,
  imgId,
  title,
  description,
  price,
  children,
  ...modifiers
}) => {
  return (
    <li className={bemModifier(modifiers, "recipe")}>
      <div className="vertical">
        <Text type="label" color="paleBrown" center>
          {type}
        </Text>
      </div>
      <div className="img" style={{ backgroundImage: `url(${food_Color})` }}>
        <Image id={imgId} url={imgUrl} width={80} height={80} hidePlaceholder />
      </div>
      <div className="content">
        <Text type="h5">{title}</Text>
        <Text type="p4">{description}</Text>
        <div>
          <Badge>{price}</Badge>
        </div>
      </div>
    </li>
  );
};
Recipe.defaultProps = {
  // This value is adopted when name prop is omitted.
};
