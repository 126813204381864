import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Icon } from "../../atoms/icon/icon";

type AlertProps = {
  /** Color of button */
  color?: "primary" | "secondary"; // Change the required prop to an optional prop.
  outline?: boolean;
  large?: boolean;
};

export const Alert: FunctionComponent<AlertProps> = ({ children, ...modifiers }) => {
  if (children) {
    return (
      <div className={bemModifier(modifiers, "alert")}>
        <Icon name="info" color="white"></Icon>
        <p>{children}</p>
      </div>
    );
  } else {
    return null;
  }
};
Alert.defaultProps = {
  color: "primary", // This value is adopted when name prop is omitted.
  outline: false,
  large: false,
};
