import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AppContext } from "src/contexts/appContext";
import { Page } from "src/layouts/page";
import { Text } from "src/components/atoms/text/text";
import { Dropdown } from "src/components/atoms/dropdown/dropdown";
import { Textarea } from "src/components/atoms/textarea/textarea";
import { ToggleButton } from "src/components/atoms/toggleButton/toggleButton";
import { Input } from "src/components/atoms/input/input";
import { Button } from "src/components/atoms/button/button";
import { UploadField } from "src/components/molecules/uploadField/uploadField";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes, getRoute } from "src/AppRouter";
import { AnnounceDetail } from "src/types/ApiItems";
import { AnnounceCreate, AnnounceUpdate } from "src/services/SmallAnnouncesApi";
import { NumberInput } from "src/components/atoms/numberInput/numberInput";
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
import { OfflineLock } from "src/components/molecules/offlineLock/offlineLock";
import { validateEmail } from "src/helpers/miscHelpers";

type AnnounceFormErrors = {
  category?: string;
  title?: string;
  email?: string;
};

export const SmallAnnounceCreateUpdate = () => {
  const defaultErrorsValue: AnnounceFormErrors = {
    category: undefined,
    title: undefined,
    email: undefined,
  };
  const [announce, setAnnounce] = useState<AnnounceDetail | null>(null);
  const [category, setCategory] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<number>();
  const [files, setFiles] = useState<File[]>();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<AnnounceFormErrors>(defaultErrorsValue);
  const history = useHistory();
  const { api, toast, isOnline } = useContext(AppContext);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      api.smallAnnounces.getAnnounce(id).then((result) => {
        setAnnounce(result);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (announce) {
      setCategory(announce.category.toString());
      setTitle(announce.title);
      setDescription(announce.description);
      setPrice(announce.price);
      setPhoneNumber(announce.publishing.contact.vendorPhoneNumber);
      setEmail(announce.publishing.contact.vendorEmail);
      setShowUserInfo(announce.displayContact);
    }
  }, [announce]);

  const scrollPage = (id: string, divToScroll): void => {
    const divToFocus = document.getElementById(id);
    const headerHeight = 100;
    if (divToFocus) {
      divToFocus.scrollIntoView();
    }
    let scrolledY = divToScroll.scrollTop;
    divToScroll.scroll(0, scrolledY - headerHeight - 30);
  };

  const getFiles = (e: File[]): void => {
    setFiles(e);
  };


  const validateForm = (divToScroll): boolean => {
    const newErrors: AnnounceFormErrors = defaultErrorsValue;
    setError(defaultErrorsValue);
    let valid = true;
    if (!title || title === "") {
      newErrors.title = "Veuillez donner un titre à votre annonce";
      valid = false;
      scrollPage("Titre", divToScroll);
    }
    if (!category || category === "") {
      newErrors.category = "Veuillez indiquer de quel genre d'annonce il s'agit";
      valid = false;
      scrollPage("Catégorie", divToScroll);
    }
    if (email && !!!validateEmail(email)) {
      newErrors.email = "Veuillez entrer un mail correct";
      valid = false;
    }
    return valid;
  };

  const getCreateData = (): AnnounceCreate => {
    return {
      title,
      description,
      price: price as number,
      displayContact: showUserInfo,
      vendorEmail: email,
      vendorPhoneNumber: phoneNumber,
      category: Number(category),
      images: files,
    };
  };

  const getUpdateData = (): AnnounceUpdate => {
    return {
      id,
      ...getCreateData(),
    };
  };
  const create = async () => {
    const success = await api.smallAnnounces.create(getCreateData());
    if (success) {
      toast.success("Annonce créée avec succès");
      setTimeout(() => {
        history.push(routes.announcesMine);
      }, 500);
    } else {
      toast.createUnknkowError();
    }
  };
  const update = async () => {
    const success = await api.smallAnnounces.update(getUpdateData());
    if (success) {
      toast.success("Annonce mise à jour avec succès");
    } else {
      toast.createUnknkowError();
    }
  };

  const onSubmit = () => {
    const divToScroll = document.getElementsByClassName("react-tiger-transition--screen")[0];
    if (!validateForm(divToScroll)) return;
    setShowModal(true);
    setTimeout(() => {
      if (id) {
        update();
      } else {
        create();
      }
    }, 3000);
  };

  const options: {} = { 1: "Je vend", 2: "Je cherche" };

  if (!id) {
    return (
      <Page pageClass="smallAnnounceCreateUpdate">
        {!isOnline && <OfflineLock />}
        <div id="top"></div>
        <PageHeader back={routes.announces} title="Créer une annonce" />
        <Container>
          <Text type="p2">
            Cette rubrique est destinée au collaborateur de l’entreprise uniquement.
            <br />
            <br />
            Ce dernier confirme la véracité des éléments fournis et n’utilise que des informations
            de contact le concernant directement.
          </Text>

          <div className="separator close"></div>

          <Text type="h4">Informations principales</Text>
          <Dropdown
            name="Catégorie"
            value={category}
            setValue={setCategory}
            options={options}
            error={error.category}
          />

          <Input name="Titre" type="text" value={title} setValue={setTitle} error={error.title} />
          <Textarea name="Description" value={description} setValue={setDescription} />

          <NumberInput name="Prix" value={price} setValue={setPrice} />

          <div className="separator"></div>

          <UploadField label="Ajouter un(des) images(s)" onSelect={getFiles} max={4} />

          <div className="separator"></div>

          <Text type="h4">Informations de contact</Text>

          <Input
            name="N° téléphone de contact"
            type="text"
            value={phoneNumber}
            setValue={setPhoneNumber}
          />
          <Input
            name="E-mail de contact"
            type="email"
            value={email}
            setValue={setEmail}
            error={error.email}
          />
          <div className="toggle">
            <ToggleButton value={showUserInfo} setValue={setShowUserInfo} />
            <Text type="p3">Afficher votre nom sur l'annonce</Text>
          </div>

          <Button large outline color="secondary" link={routes.announces} transitionBack>
            Annuler
          </Button>
          <Button large onClick={onSubmit}>
            Soumettre mon annonce
          </Button>
        </Container>
        <LoadingScreen show={showModal} message="Uploading des fichiers en cours" backdrop />
      </Page>
    );
  } else {
    return (
      <Page pageClass="smallAnnounceCreateUpdate">
        <div id="top"></div>
        <PageHeader
          back={getRoute(routes.announcesDetail, { id: id })}
          title="Modifer une annonce"
        />
        <Container>
          <Text type="p2">
            Cette rubrique est destinée au collaborateur de l’entreprise uniquement.
            <br />
            <br />
            Ce dernier confirme la véracité des éléments fournis et n’utilise que des informations
            de contact le concernant directement.
          </Text>
          {announce && (
            <>
              <div className="separator close"></div>
              <Text type="h4">Informations principales</Text>
              <Dropdown
                name="Catégorie"
                value={category}
                setValue={setCategory}
                options={options}
                error={error.category}
              />
              <Input
                name="Titre"
                type="text"
                value={title}
                setValue={setTitle}
                error={error.title}
              />
              <Textarea name="Description" value={description} setValue={setDescription} />
              <Input name="Prix" type="text" value={price} setValue={setPrice} />
              <div className="separator"></div>
              <UploadField label="Ajouter un(des) images(s)" />
              <div className="separator"></div>
              <Text type="h4">Informations de contact</Text>
              <Input
                name="N° téléphone de contact"
                type="text"
                value={phoneNumber}
                setValue={setPhoneNumber}
              />
              <Input
                name="E-mail de contact"
                type="email"
                value={email}
                setValue={setEmail}
                error={error.email}
              />
              <div className="toggle">
                <ToggleButton value={showUserInfo} setValue={setShowUserInfo} />
                <Text type="p3">Afficher votre nom sur l'annonce</Text>
              </div>
              <Button
                large
                outline
                color="secondary"
                link={getRoute(routes.announcesDetail, { id: id })}
                transitionBack
              >
                Annuler
              </Button>
              <Button large onClick={onSubmit}>
                Modifier mon annonce
              </Button>
            </>
          )}
        </Container>
        <LoadingScreen show={showModal} message="Uploading des fichiers en cours" backdrop />
      </Page>
    );
  }
};
