import { FetchApi } from "./FetchApi";
import { StaticData } from "../types/dataTypes";
import { CustomError } from "src/helpers/CustomError";
import { ErrorCodes } from "src/helpers/enum";
export class StaticApi extends FetchApi {
  validateStaticData (data) {
    if (!data.contact || !data.global || !data.pages) {
      return false;
    }
    return true;
  }
  async getAll() {
    const data = await this.get<StaticData>(`/frontstaticdata`, { 
      cacheKey: "frontstaticdata",
      validate: this.validateStaticData
    });
    // Check data
    if(!this.validateStaticData(data)){
      throw new CustomError(ErrorCodes.API_BAD_RESPONSE_FORMAT, { info: "STATIC_DATA_MALFORMATED" });
    }
    return data;
  }
  getManifest() {
    return this.getAny<any>(`/manifest.json?v=${new Date().getTime()}`, { cache: false });
  }
  getHomeItems() {
    const cacheDurationMinute = 30;
    return this.getAny<any>(`/assets/home.json${this.cacheBuster(cacheDurationMinute)}`, {
      cacheDuration: 1000 * 60 * cacheDurationMinute,
      cacheKey: "homeItems",
      validate: (data) => typeof data === "object",
    });
  }
}
