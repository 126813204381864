import { routes, RoutesValues } from "src/AppRouter";
import { matchPath } from "react-router-dom";
import { PatekApi } from "src/services/PatekApi";
import { NewsEntreprise, OfferRH, NewsDetail, OfferDetail } from "src/types/ApiItems";
import { AppNewsType } from "src/types/dataTypes";
import { HomeItemId } from "src/contexts/appContext";

type ListingRoutes = {
  id: HomeItemId;
  type: AppNewsType;
  route: string;
  routeDetail?: string;
  getAll(): Promise<NewsEntreprise[] | OfferRH[]>;
  getById(id: number): Promise<NewsDetail | OfferDetail>;
  getIsNew(): Promise<NewsEntreprise[] | OfferRH[]>;
};

const getIsNew = async (contents) => {
  let result: NewsEntreprise[] | OfferRH[];
  try {
    result = await contents;
  } catch (e) {
    console.error(e, "error");
    return [];
  }
  const tabFilter = result.filter((content) => {
    return content.isNew;
  });
  return tabFilter;
};
export const numberOfDayNewNotif = 12;
// const getIsNewByDate = async (contents) => {
//   let result: NewsEntreprise[] | OfferRH[];
//   try {
//     result = await contents;
//   } catch (e) {
//     console.error(e, "error");
//     return [];
//   }
//   const tabFilter = result.filter((content) => {
//     return moment() < moment(content.publishDate).add(numberOfDayNewNotif, "days");
//   });
//   return tabFilter;
// };
export const getListingRoutes = (api: PatekApi): ListingRoutes[] => [
  {
    id: "job",
    type: "job",
    route: routes.job,
    routeDetail: routes.jobDetail,
    getAll: () => api.jobs.getAll(),
    getById: (id) => api.jobs.getById(id),
    getIsNew: () => getIsNew(api.jobs.getAll()),
  },
  {
    id: "newsCompany",
    type: "company",
    route: routes.newsCompany,
    routeDetail: routes.newsCompanyDetail,
    getAll: () => api.news.companyGetNotDontMiss(),
    getById: (id) => api.news.companyGetById(id),
    getIsNew: () => getIsNew(api.news.companyGetNotDontMiss()),
  },
  {
    id: "newsInformation",
    type: "information",
    route: routes.newsInformation,
    routeDetail: routes.newsInformationDetail,
    getAll: () => api.news.informationGetAll(),
    getById: (id) => api.news.informationGetById(id),
    getIsNew: () => getIsNew(api.news.informationGetAll()),
  },
  {
    id: "newsCommittee",
    type: "committee",
    route: routes.newsCommittee,
    routeDetail: routes.newsCommitteeDetail,
    getAll: () => api.news.committeeGetAll(),
    getById: (id) => api.news.committeeGetById(id),
    getIsNew: () => getIsNew(api.news.committeeGetAll()),
  },
  {
    id: "newsdelegation",
    type: "delegation",
    route: routes.delegationSyndicale,
    routeDetail: routes.delegationSyndicaleDetail,
    getAll: () => api.news.delegationGetAll(),
    getById: (id) => api.news.delegationGetById(id),
    getIsNew: () => getIsNew(api.news.delegationGetAll()),
  },
  {
    id: "newsOrganisation",
    type: "organisation",
    route: routes.newsOrganisation,
    routeDetail: routes.newsOrganisationDetail,
    getAll: () => api.news.organisationGetAll(),
    getById: (id) => api.news.organisationGetById(id),
    getIsNew: () => getIsNew(api.news.organisationGetAll()),
  },
  {
    id: "newsRestaurant",
    type: "restaurant",
    route: routes.newsRestaurant,
    routeDetail: routes.newsRestaurantDetail,
    getAll: () => api.news.restaurantGetAll(),
    getById: (id) => api.news.restaurantGetById(id),
    getIsNew: () => getIsNew(api.news.restaurantGetAll()),
  },
  {
    id: "newsSport",
    type: "sport",
    route: routes.newsSport,
    routeDetail: routes.newsSportDetail,
    getAll: () => api.news.sportGetAll(),
    getById: (id) => api.news.sportGetById(id),
    getIsNew: () => getIsNew(api.news.sportGetAll()),
  },
  {
    id: "newsDontMiss",
    type: "dontMiss",
    route: routes.newsDontMiss,
    routeDetail: routes.newsDontMissDetail,
    getAll: () => api.news.companyGetDontMiss(),
    getById: (id) => api.news.companyGetById(id),
    getIsNew: () => getIsNew(api.news.companyGetDontMiss()),
  },
];

export const getRoute = (path: RoutesValues, data?: Object) => {
  if (data) {
    Object.keys(data).forEach((key) => {
      path = path.replace(":" + key, data[key]);
    });
  }
  return path;
};

export const isRoute = (route: RoutesValues, pathname: string) => {
  const match = matchPath(pathname, {
    path: route,
    exact: true,
    strict: false,
  });
  return match ? true : false;
};
