import moment from "moment";
import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { IconNames, AllIconNames } from "src/components/atoms/icon/icon";
import { File } from "src/types/ApiItems";
import { ListItemFile } from "../../listItemFile/listItemFile";

export const ListDocumentItem: FunctionComponent<File> = (item) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const iconDocument = !isOpen ? "documentClose" : "documentOpen";
  const handleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };
  const iconName: IconNames = item.extension ? item.extension : AllIconNames["file"];
  const date = moment(item.publishedOn).format("DD.MM.YY");

  const childNode = item.isAFolder ? (
    <li className="list-document-item">
      <ListItemFile
        key={item.id}
        icon={iconDocument}
        overtitle={`${date} | ${byteToMb(item.size)}`}
        title={item.title}
        fileCount={item.fileCount || item.children.length}
        children={item.children}
        isFolder={item.isAFolder}
        folderId={item.id}
        onClick={handleOpen}
        collapsible={!item.fileCount}
      />
      {item.children && isOpen && (
        <ul className="list-document-item__sub">
          {item.children.map((childItem) => (
            <ListDocumentItem key={childItem.id} {...childItem} />
          ))}
        </ul>
      )}
    </li>
  ) : (
    <li>
      <ListItemFile
        key={item.id}
        icon={iconName}
        overtitle={`${date} | ${byteToMb(item.size)}`}
        title={item.title}
        link={item.fileUrl}
        author={item.publishedBy}
      />
    </li>
  );

  return childNode;
};

export function byteToMb(size: number | string) {
  const converted = Number(size) / Math.pow(1024, 2);
  const rounded = (Math.round(converted * 100) / 100).toFixed(2);
  return `${rounded} MB`;
}
