import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Icon, IconNames } from "../icon/icon";
import { Text } from "../text/text";
export type InputProps = {
  /** Color of button */
  name: string;
  type?: string;
  value: string | number | undefined;
  setValue: any;
  error?: any;
  step?: any;
  label?: string;
  variant?:"elevation" | "outline",
  appendIcon?: IconNames,
  disabled?: boolean
};

export const Input: FunctionComponent<InputProps> = ({
  children,
  name,
  type,
  value,
  setValue,
  step,
  label,
  error,
  appendIcon,
  variant="elevation",
  disabled,
  ...modifiers
}) => {
  const classnames : string[] = []
  classnames.push(bemModifier(modifiers, "input"))
  classnames.push('input--'+variant)
  if(appendIcon){
    classnames.push('input--append')
  }
  if(error){
    classnames.push('input--error')
  }
  return (
    <>
      <div className={classnames.join(" ")}>
        <input
          className="input__input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type={type}
          id={name}
          name={name}
          step={step}
          disabled={disabled}
          style={{ borderColor: error ? "#f95111" : "" }}
          required
        />
        <label htmlFor={name}>{label ? label : name}</label>
        {appendIcon && <Icon className="input__append-icon" name={appendIcon} />}
      </div>
      {error && (
        <Text type="p3" color="orange">
          {error}
        </Text>
      )}
    </>
  );
};
