import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Text } from "../../atoms/text/text";

type DescriptionProps = {
  /** Color of button */
  title1: string;
  desc1: string;
  title2?: string;
  desc2?: string;
  title3?: string;
  desc3?: string;
  title4?: string;
  desc4?: string;
};

export const Description: FunctionComponent<DescriptionProps> = ({
  title1,
  desc1,
  title2,
  desc2,
  title3,
  desc3,
  title4,
  desc4,
  children,
  ...modifiers
}) => {
  return (
    <div className={bemModifier(modifiers, "description")}>
      <dl>
        <dt>
          <Text type="subtitle">{title1}</Text>
        </dt>
        <dd>
          <Text type="h4">{desc1}</Text>
        </dd>
      </dl>
      {title2 && (
        <dl className="secondDesc">
          <dt>
            <Text type="subtitle">{title2}</Text>
          </dt>
          <dd>
            <Text type="h4">{desc2}</Text>
          </dd>
        </dl>
      )}
      {title3 && (
        <dl>
          <dt>
            <Text type="subtitle">{title3}</Text>
          </dt>
          <dd>
            <Text type="h4">{desc3}</Text>
          </dd>
        </dl>
      )}
      {title4 && (
        <dl className="secondDesc">
          <dt>
            <Text type="subtitle">{title4}</Text>
          </dt>
          <dd>
            <Text type="h4">{desc4}</Text>
          </dd>
        </dl>
      )}
    </div>
  );
};
