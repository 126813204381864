import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { Input, InputProps } from "../input/input";

type searchBarProps = Omit<InputProps, "variant" | "appendIcon">

export const SearchBar: FunctionComponent<searchBarProps> = ({
  ...props
}) => {
  return (
    <Input {...props} variant="outline" appendIcon="search" />
  );
};
