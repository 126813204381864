import React from "react";
import "./assets/scss/App.scss";
import { AppContextProvider } from "./contexts/appContext";
import AppRouter from "./AppRouter";
import { ToastWrapper } from "./components/atoms/toast/toastWrapper";
import { ErrorBoundary } from "./components/molecules/errorBoundary/errorBoundary";
import { ErrorPage } from "./page/_utils/errorPage/errorPage";

function App() {
  return (
    <ErrorBoundary name="app">
      <ErrorPage></ErrorPage>
      <AppContextProvider>
        <ToastWrapper />
        <AppRouter />
      </AppContextProvider>
    </ErrorBoundary>
  );
}

export default App;
