import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";

type BadgePillProps = {
  /** Color of button */
  color?: "primary" | "secondary"; // Change the required prop to an optional prop.
};

export const BadgePill: FunctionComponent<BadgePillProps> = ({ children, ...modifiers }) => {
  if (children) {
    return <span className={bemModifier(modifiers, "badgePill")}>{children}</span>;
  } else {
    return null;
  }
};
BadgePill.defaultProps = {
  color: "primary", // This value is adopted when name prop is omitted.
};
