import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../../helpers/bemModifier";

export const List: FunctionComponent = ({ children, ...modifiers }) => {
  if (children) {
    return <ul className={bemModifier(modifiers, "list")}>{children}</ul>;
  } else {
    return null;
  }
};
