import Cookies from "universal-cookie";
import localforage from "localforage";
import { localForageKeys } from "./localForageKey";

export const formatPrice = (price) => {
  return price ? "CHF " + price.toFixed(2) : "A discuter";
};
export const validateEmail = (email: string): boolean => {
  const regex: RegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regex.test(email);
};

export const forceRefresh = async () => {
  await localforage.clear();
  await localforage.setItem(localForageKeys.backHome, true);
  window.location.reload(true);
};

export const cookies = new Cookies();
