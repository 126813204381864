import React, { FunctionComponent } from "react";
import { Portal } from "react-portal";
import { bemModifier } from "src/helpers/bemModifier";
import { Button } from "../../atoms/button/button";
export type ConfirmModalProps = {
  title?: string;
  onConfirm(): void;
  onCancel(): void;
  confirmLabel?: string;
  cancelLabel?: string;
  show?: boolean;
};
export const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  title,
  show,
  onConfirm,
  onCancel,
  children,
  confirmLabel,
  cancelLabel,
  ...modifiers
}) => {
  return (
    <Portal>
      {show && (
        <div className={bemModifier(modifiers, "confirm-modal")}>
          <div className="confirm-modal__wrap">
            {title && <div className="confirm-modal__title">{title}</div>}
            <div className="confirm-modal__message">{children}</div>
            <div className="confirm-modal__footer">
              <Button onClick={onCancel} outline color="secondary">
                {cancelLabel}
              </Button>
              <Button onClick={onConfirm}>{confirmLabel}</Button>
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

ConfirmModal.defaultProps = {
  confirmLabel: "Confirmer",
  cancelLabel: "Annuler",
};
