import React, { FunctionComponent } from "react";
import { Skeleton } from "@material-ui/lab";

export type SkeletonProps = {
  type?: SkeletonType;
  width?: number;
  height?: number;
};
export type SkeletonType = "text" | "circle" | "rect";

export const MySkeleton: FunctionComponent<SkeletonProps> = ({ type, width, height }) => {
  return <Skeleton variant={type} width={width} height={height} />;
};
