import React, { FunctionComponent } from "react";
import { Route, Screen } from "react-tiger-transition";

export type RouteTransitionProps = {
  path: string;
  exact?: boolean;
};
export const RouteTransition: FunctionComponent<RouteTransitionProps> = ({
  path,
  exact,
  children,
}) => {
  return (
    <Route path={path} exact={exact ? true : false}>
      <Screen>{children}</Screen>
    </Route>
  );
};
