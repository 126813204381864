import React, { FunctionComponent } from "react";
import moment from "src/helpers/moment";
import { bemModifier } from "../../../helpers/bemModifier";
import { Badge } from "../../atoms/badge/badge";
import { Image } from "../../atoms/image/image";
import { Date } from "../../atoms/date/date";
import { Text } from "../../atoms/text/text";
import { BadgePill } from "../../atoms/badgePill/badgePill";
import { RouterLink } from "../../atoms/routerLink/routerLink";
import { MySkeleton } from "../../atoms/skeleton/mySkeleton";

type ListItemProps = {
  highlight?: boolean;
  fade?: boolean;
  tag?: string;
  title?: string;
  overtitle?: string;
  date?: string;
  imgId?: string;
  newLabel?: string;
  link?: string;
  externalLink?: string;
  skeleton?: boolean;
  unread?: boolean;
  classModifier?: string;
  imgPlaceholder?: string;
};

export const ListItem: FunctionComponent<ListItemProps> = ({
  title,
  highlight,
  overtitle,
  tag,
  date,
  imgId,
  newLabel,
  link,
  externalLink,
  skeleton,
  classModifier,
  imgPlaceholder,
  ...modifiers
}) => {
  if (skeleton) {
    return (
      <li className={bemModifier(modifiers, "listItem")}>
        <div className="img">
          <MySkeleton type="rect" width={80} height={80} />
        </div>
        <div className="content">
          <div className="meta">
            <MySkeleton type="text" width={120} height={11} />
            <MySkeleton type="text" width={36} height={17} />
          </div>
          <p>
            <MySkeleton type="text" width={200} height={19} />
            <MySkeleton type="text" width={120} height={19} />
          </p>
          <div className="bottom">
            <MySkeleton type="text" width={40} height={11} />
          </div>
        </div>
      </li>
    );
  } else {
    const badeColor = () => {
      if (newLabel === "en attente") {
        return "secondary";
      } else if (newLabel === "périmée") {
        return "secondary";
      } else {
        return "primary";
      }
    };
    return (
      <li className={bemModifier(modifiers, "listItem") + " listItem--" + classModifier}>
        <div className="listItem__img">
          <Image id={imgId} width={160} height={160} placeholder={imgPlaceholder} />
        </div>
        <div className="listItem__content">
          <div className="listItem__meta">
            <Text type="subtitle" color={highlight ? "orange" : ""}>
              {overtitle}
            </Text>
            <BadgePill color={badeColor()}>{newLabel}</BadgePill>
          </div>
          <Text className="listItem__title" type="h4" color={highlight ? "orange" : ""}>
            {title}
          </Text>
          <div className="listItem__bottom">
            <Badge>{tag}</Badge>
            {date && (
              <Date color={highlight ? "orange" : ""}>{moment(date).format("DD.MM.YY")}</Date>
            )}
          </div>
        </div>
        {externalLink && <a href={externalLink} target="_blank" rel="noreferrer"><span></span></a>}
        {!externalLink && link && <RouterLink to={link} transition="next"></RouterLink>}
      </li>
    );
  }
};
ListItem.defaultProps = {
  // This value is adopted when name prop is omitted.
};
