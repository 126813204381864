import { FetchApi } from "./FetchApi";
export type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
};

export class UserApi extends FetchApi {
  async getUserInfo(): Promise<UserInfo> {
    const data = await this.get<string[]>(`/WebPageHelper/UserInfo`, { cache: false });
    return {
      firstName: data[0],
      lastName: data[1],
      email: data[3],
    };
  }
  async sendSuggestion(data: { message: string; email: string }) {
    return this.post(`/MySuggestion`, { message: data.message, myMail: data.email });
  }
  async sendCertificat(data: { myMail: string; message: string; file: any }) {
    return this.postFormData(`/services-rh`, this.objectToFormdata(data, ["file"]));
  }
}
