import React, { FunctionComponent, useEffect, useState } from "react";
export type ToastProps = {
  message: string;
  type: "error" | "success";
  timeout?: number;
  onRemove?(): void;
};
const transitionDuration = 300;
export const Toast: FunctionComponent<ToastProps> = ({ onRemove, timeout, type, message }) => {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setShow(true), 10);
    setTimeout(() => {
      setShow(false);
      if (!onRemove) return;
      setTimeout(() => onRemove(), transitionDuration);
    }, timeout);
  }, []); // eslint-disable-line
  return <div className={`toast toast--${type} ${show ? "toast--show" : ""}`}>{message}</div>;
};
Toast.defaultProps = {
  timeout: 3000,
};
