import React, { FunctionComponent } from "react";
import { Input } from "../input/input";
export type NumberInputProps = {
  value?: number;
  name: string;
  setValue(value: number | undefined): void;
};
const formatValue = (value: string | number) => {
  return value || value === "0" ? Number(value) : undefined;
};
export const NumberInput: FunctionComponent<NumberInputProps> = ({ value, setValue, name }) => {
  const handleChange = (value: string) => {
    const newVal = formatValue(value);
    setValue(newVal);
  };
  return (
    <Input
      type="number"
      name={name}
      step="0.01"
      value={value ? value.toString() : ""}
      setValue={handleChange}
    />
  );
};
