import React, { FunctionComponent } from "react";
import { MySkeleton } from "./mySkeleton";

export const DescriptionSkeleton: FunctionComponent = () => {
  return (
    <div className="description">
      <dl>
        <dt>
          <MySkeleton type="text" width={70} height={11} />
        </dt>
        <dd>
          <MySkeleton type="text" width={130} height={17} />
        </dd>
      </dl>
      <dl className="secondDesc">
        <dt>
          <MySkeleton type="text" width={70} height={11} />
        </dt>
        <dd>
          <MySkeleton type="text" width={130} height={17} />
        </dd>
      </dl>
      <dl>
        <dt>
          <MySkeleton type="text" width={70} height={11} />
        </dt>
        <dd>
          <MySkeleton type="text" width={130} height={17} />
        </dd>
      </dl>
      <dl className="secondDesc">
        <dt>
          <MySkeleton type="text" width={70} height={11} />
        </dt>
        <dd>
          <MySkeleton type="text" width={130} height={17} />
        </dd>
      </dl>
    </div>
  );
};
