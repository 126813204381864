import ClientOAuth2 from "client-oauth2";

export const oauthClient = new ClientOAuth2({
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  clientSecret: "",
  accessTokenUri: process.env.REACT_APP_OAUTH_TOKEN_URL,
  authorizationUri: process.env.REACT_APP_OAUTH_AUTHORIZATION_URL,
  redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URL,
  scopes: process.env.REACT_APP_OAUTH_SCOPE
    ? process.env.REACT_APP_OAUTH_SCOPE.split(",")
    : ["username"],
});
