import React, { FunctionComponent, useEffect, useRef, useState } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Text } from "../../atoms/text/text";
import { BadgePill } from "../../atoms/badgePill/badgePill";
import { Icon } from "../../atoms/icon/icon";
import { RouterLink } from "../../atoms/routerLink/routerLink";
import { Image } from "../../atoms/image/image";

type HeroProps = {
  /** Color of button */
  image?: string;
  backColor?: "black" | "white";
  date?: string;
  title?: string;
  badge?: string;
  arrowText?: boolean;
  href: string;
  back?: any;
  small?: boolean;
  className?: string;
};

export const Hero: FunctionComponent<HeroProps> = ({
  image,
  date,
  title,
  badge,
  arrowText,
  href,
  small,
  children,
  className,
  backColor,
  ...modifiers
}) => {
  const backgroundRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const contentRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [stickyBg, setStickyBg] = useState<boolean>(false);
  const [bgHeight, setBgHeight] = useState<number>(0);
  const isSmall = small || (!image && !children);
  const textColor = isSmall && backColor !== "black" ? "paleBrown" : "white";
  useEffect(() => {
    const handleScroll = (e) => {
      if (!backgroundRef.current || !contentRef.current) {
        return;
      }
      const bgRect = backgroundRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const bottomPos = bgRect.height - e.currentTarget.scrollTop;
      if (bottomPos < contentRect.height) {
        setStickyBg(true);
        setBgHeight(bgRect.height);
      } else {
        setStickyBg(false);
      }
    };
    const screen = backgroundRef.current?.closest(".react-tiger-transition--screen");
    screen?.addEventListener("scroll", handleScroll);
    return () => screen?.removeEventListener("scroll", handleScroll);
  }, []); // eslint-disable-line

  return (
    <div
      className={`${bemModifier({ ...modifiers, small: isSmall, backColor }, "hero")}${
        className ? " " + className : ""
      }`}
    >
      <div className={`hero__content`}>
        <div ref={contentRef} className={`hero__container ${image || children ? "shadow" : ""}`}>
          <RouterLink to={href} transition="back" className="left">
            <div className="arrow">
              <Icon name="arrow_left" color={textColor}></Icon>
            </div>
            {arrowText && (
              <div className="back">
                <Text type="link" color={textColor}>
                  Retour
                </Text>
              </div>
            )}
          </RouterLink>
          <div className="center">
            <div className="title">
              <Text type="subtitle" color={textColor}>
                {title}
              </Text>
            </div>
            <div className="date">
              <Text type="p4" color={textColor}>
                {date}
              </Text>
            </div>
          </div>
          <div className="right">
            <div className="notif">
              <BadgePill>{badge}</BadgePill>
            </div>
          </div>
        </div>
      </div>
      {(image || children) && !small && (
        <div
          className={`hero__background${stickyBg ? " hero__background--sticky" : ""}`}
          ref={backgroundRef}
          style={{ minHeight: bgHeight + "px" }}
        >
          {children && <div className="hero__background-wrap hero__slider">{children}</div>}
          {!children && (
            <div className="hero__background-wrap hero__image">
              <Image id={image} cover width={1125} height={900} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
Hero.defaultProps = {
  // This value is adopted when name prop is omitted.
};
