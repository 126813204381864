import React, { FunctionComponent, useEffect, useState } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { TabProps } from "./tab";
import { useHistory, useLocation } from "react-router-dom";

export type TabsProps = {
  onChange?(index: number): void;
  router?: boolean;
};

const routesMatch = (route1: string | undefined, route2: string | undefined) => {
  const routes = {
    route1: route1 ? route1.replace(/\/$/, "") : "",
    route2: route2 ? route2.replace(/\/$/, "") : "",
  };
  return routes.route1 === routes.route2;
};

export const Tabs: FunctionComponent<TabsProps> = ({
  children,
  onChange,
  router,
  ...modifiers
}) => {
  const [activeTab, setActiveTab] = useState<number>(-1);
  const [init, setInit] = useState<boolean>(false);
  let history = useHistory();
  const location = useLocation();

  const tabsArray = React.useMemo(() => {
    return React.Children.map(children as React.ReactElement<TabProps>, (tab, index) => {
      if (tab.props.active) setActiveTab(index);
      if (router && tab.props.route && routesMatch(tab.props.route, location.pathname)) {
        setActiveTab(index);
      }
      return tab;
    });
  }, [1]); // eslint-disable-line

  function changeTab(tabIndex: number) {
    if (tabIndex === activeTab) return;

    const tabClicked = tabsArray[tabIndex];
    const { route, onSelect } = tabClicked.props;
    setActiveTab(tabIndex);
    // emit onChange event of <tabs> group
    if (onChange) onChange(tabIndex);
    // emit onSelect event of <tab> item
    if (onSelect) onSelect();
    if (route) history.push(route);
  }

  // send initial select event
  useEffect(() => {
    if (!init && activeTab > -1) {
      const tab = tabsArray[activeTab];
      const { onSelect } = tab.props;
      if (onSelect) onSelect();
      setInit(true);
    }
  }, [activeTab]); // eslint-disable-line

  // Watch router to track previous/next page browser action
  React.useEffect(() => {
    if (router) {
      const index = tabsArray.findIndex((tab) => routesMatch(tab.props.route, location.pathname));
      if (index > -1 && index !== activeTab) changeTab(index);
    }
  }, [location]); // eslint-disable-line

  const tabsButtons = tabsArray.map((tab, index) => {
    const { label } = tab.props;
    return (
      <button
        className={`tab__link ${activeTab === index ? "active" : ""}`}
        onClick={() => changeTab(index)}
        key={index}
      >
        {label}
      </button>
    );
  });

  const tabContents = tabsArray.map((tab, index) => {
    return tab.props.children ? (
      <div className={`tab__content ${activeTab === index ? "active" : ""}`} key={index}>
        {tab.props.children}
      </div>
    ) : (
      ""
    );
  });

  return (
    <div className={bemModifier(modifiers, "tabs")}>
      <div className="tab">{tabsButtons}</div>
      <div className="tab__contents">{tabContents}</div>
    </div>
  );
};
