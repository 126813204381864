import { OfferRH, OfferDetail } from "src/types/ApiItems";
import { FetchApi } from "./FetchApi";

export class JobsApi extends FetchApi {
  getAll() {
    return this.get<OfferRH[]>("/news-rh/offer");
  }
  getById(id: number) {
    return this.get<OfferDetail>(`/news-rh/offer/${id}`);
  }
}
