import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { DateCalendar } from "../dateCalendar/dateCalendar";
import moment from "src/helpers/moment";
import { WeekMenu } from "src/types/ApiItems";

type WeekCalendarProps = {
  days: Array<WeekMenu>;
  actual: number;
  setActual: any;
};

export const WeekCalendar: FunctionComponent<WeekCalendarProps> = ({
  days,
  actual,
  setActual,
  children,
  ...modifiers
}) => {
  const daysList = days.map((date, index) => {
    const day = moment(date.dayOfWeek).format("ddd");
    const month = moment(date.dayOfWeek).format("DD");
    return (
      <DateCalendar
        id={index}
        setActual={setActual}
        day={day}
        date={month}
        actual={actual === index}
        key={index}
        notif={
          date.restaurants.filter((resto) => resto.menus.filter((menu) => !menu.category).length)
            .length
            ? true
            : false
        }
        disabled={date.restaurants.find((resto) => resto.menus.length > 0) ? false : true}
      ></DateCalendar>
    );
  });
  return <ul className={bemModifier(modifiers, "weekCalendar")}>{daysList}</ul>;
};
