import React, { FunctionComponent, useEffect, useState } from "react";
import { Icon } from "../../atoms/icon/icon";
import { Text } from "src/components/atoms/text/text";

export type UploadFieldProps = {
  onSelect?(data: any): void;
  label: string;
  error?: any;
  max?: number;
};
type stateFile = { preview: string; data: any; name: string; type: string };
export const UploadField: FunctionComponent<UploadFieldProps> = ({
  label,
  onSelect,
  error,
  max,
}) => {
  const maxFiles = max ? max : 3;
  const [selectedFiles, setSelectedFiles] = useState<stateFile[]>([]);
  const [maxError, setMaxError] = useState<string>();
  const emitChanges = () => {
    if (onSelect) onSelect(selectedFiles.map((file) => file.data));
  };
  const handleChange = (e) => {
    const dataFiles: stateFile[] = [];
    const { files } = e.target;
    if (files.length + selectedFiles.length > maxFiles) {
      setMaxError(`Vous pouvez uploader ${maxFiles} fichiers max`);
      setTimeout(() => {
        setMaxError(undefined);
      }, 4000);
      return;
    }
    for (let i = 0; i < files.length; i++) {
      dataFiles.push({
        data: files[i],
        preview: URL.createObjectURL(files[i]),
        name: files[i].name,
        type: files[i].type,
      });
    }
    setSelectedFiles([...dataFiles, ...selectedFiles]);
  };
  const handleRemove = (index) => {
    if (index > -1) {
      const array = [...selectedFiles];
      array.splice(index, 1);
      setSelectedFiles(array);
    }
  };

  useEffect(() => {
    emitChanges();
    // eslint-disable-next-line
  }, [selectedFiles]);
  return (
    <>
      <Text type="h4">{label}</Text>
      <div className="upload-field">
        <label
          className={"upload-field__input" + (selectedFiles.length >= maxFiles ? " disabled" : "")}
        >
          <Icon name="plus" />
          <input
            type="file"
            onChange={handleChange}
            accept="image/x-png,image/jpeg,image/gif,application/pdf"
            multiple
            disabled={selectedFiles.length >= maxFiles}
          />
        </label>
        {selectedFiles.map((file, index) => (
          <div
            className="upload-field__preview"
            style={{ backgroundImage: `url(${file.preview})` }}
            key={index}
          >
            {file.type === "application/pdf" && (
              <div className="upload-field__icon">
                <Icon name="actus"></Icon>
              </div>
            )}
            <button className="upload-field__remove" onClick={() => handleRemove(index)}>
              <Icon name="close" color="white" />
            </button>
          </div>
        ))}
      </div>
      {error && (
        <Text type="p3" color="orange">
          {error}
        </Text>
      )}
      {maxError && (
        <Text type="p3" color="orange">
          {maxError}
        </Text>
      )}
    </>
  );
};
UploadField.defaultProps = {
  max: 3,
};
