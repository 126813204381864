import React from 'react';
import { MySkeleton } from './mySkeleton';

export const ListItemFileSkeleton = () => {
    return <div className="listItemFile">
        <div className="listItemFile__level">
            <div className="listItemFile__icon">
                <MySkeleton type="circle" width={36} height={36} />
            </div>
            <div className="listItemFile__content">
                <MySkeleton type="text" width={60} height={11} />
                <MySkeleton type="text" width={40} height={11} />
                <MySkeleton type="text" width={44} height={11} />
            </div>
        </div>
    </div>
}