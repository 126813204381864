import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Text } from "../text/text";

type TextareaProps = {
  /** Color of button */
  value: string;
  setValue: any;
  name: string;
  error?: any;
};

export const Textarea: FunctionComponent<TextareaProps> = ({
  children,
  value,
  name,
  setValue,
  error,
  ...modifiers
}) => {
  return (
    <>
      <div className={bemModifier(modifiers, "textarea")}>
        <textarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={name}
          required
          style={{ borderColor: error ? "#f95111" : "" }}
        />
        <label htmlFor={name}>{name}</label>
      </div>
      {error && (
        <Text type="p3" color="orange">
          {error}
        </Text>
      )}
    </>
  );
};
