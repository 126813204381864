import React, { FunctionComponent } from "react";
import { List } from "../../organisms/lists/list/list";
import { MySkeleton } from "./mySkeleton";

export type ListItemSkeletonProps = {
  numberItem: number;
};

export const ListItemSkeleton: FunctionComponent<ListItemSkeletonProps> = ({ numberItem }) => {
  return (
    <List>
      {[...Array(numberItem)].map((_, index) => {
        return (
          <li className="listItem" key={index}>
            <div className="listItem__img">
              <MySkeleton type="rect" width={80} height={80} />
            </div>
            <div className="listItem__content">
              <div className="listItem__meta">
                <MySkeleton type="text" width={120} height={11} />
                <MySkeleton type="text" width={36} height={17} />
              </div>
              <p>
                <MySkeleton type="text" width={200} height={19} />
                <MySkeleton type="text" width={120} height={19} />
              </p>
              <div className="listItem__bottom">
                <MySkeleton type="text" width={40} height={11} />
              </div>
            </div>
          </li>
        );
      })}
    </List>
  );
};
