import React, { FunctionComponent, useContext } from "react";
import { Button } from "src/components/atoms/button/button";
import { Text } from "src/components/atoms/text/text";
import { AppContext } from "src/contexts/appContext";
export type HealthAccidentButtonsProps = {
  title: string;
  yesRoute: string;
  noRoute: string;
};
export const HealthAccidentButtons: FunctionComponent<HealthAccidentButtonsProps> = ({
  title,
  yesRoute,
  noRoute,
}) => {
  const { strings } = useContext(AppContext);
  return (
    <div className="ctaBool">
      <Text type="p2" center>
        {title}
      </Text>
      <div className="yesno">
        <Button color="secondary" large outline link={noRoute}>
          {strings?.global.no}
        </Button>
        <Button large link={yesRoute}>
          {strings?.global.yes}
        </Button>
      </div>
    </div>
  );
};
