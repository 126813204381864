import * as React from "react";
import { Page } from "src/layouts/page";
import { Tabs } from "src/components/organisms/tabs/tabs";
import { Tab } from "src/components/organisms/tabs/tab";
import { AppContext } from "src/contexts/appContext";
import { ListAnnounce } from "src/components/organisms/lists/listAnnounce/listAnnounce";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { routes } from "src/AppRouter";
import { Announce } from "src/types/ApiItems";

export const SmallAnnounces = () => {
  const [state, setState] = React.useState<{
    all: Announce[] | undefined;
    my: Announce[] | undefined;
  }>({
    all: undefined,
    my: undefined,
  });

  const [currentView, setCurrentView] = React.useState<"all" | "my" | undefined>();

  React.useEffect(() => {
    if (!currentView) return;
    if (currentView === "all" && !state.all) {
      api.smallAnnounces.getAll().then((res) => {
        setState({ ...state, all: res });
      });
    } else if (!state.my) {
      api.smallAnnounces.getAllMy().then((res) => {
        setState({ ...state, my: res });
      });
    }
  }, [currentView]); // eslint-disable-line

  const { api } = React.useContext(AppContext);

  return (
    <Page>
      <PageHeader flat title="Petites annonces" back={routes.home} add={routes.announcesCreate} />
      <Tabs router>
        <Tab
          label="Toutes les annonces"
          route={routes.announces}
          onSelect={() => setCurrentView("all")}
        />
        <Tab
          label="Mes annonces"
          route={routes.announcesMine}
          onSelect={() => setCurrentView("my")}
        />
      </Tabs>
      <ListAnnounce
        announces={currentView ? state[currentView] : undefined}
        current={currentView}
      />
    </Page>
  );
};
