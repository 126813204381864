import React, { useContext } from "react";
import { Page } from "src/layouts/page";
import { Text } from "src/components/atoms/text/text";
import { useState } from "react";
import { Textarea } from "src/components/atoms/textarea/textarea";
import { Button } from "src/components/atoms/button/button";
import { UploadField } from "src/components/molecules/uploadField/uploadField";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { useHistory } from "react-router-dom";
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
import { AppContext } from "src/contexts/appContext";
import { OfflineLock } from "src/components/molecules/offlineLock/offlineLock";
import { Input } from "src/components/atoms/input/input";
import { validateEmail } from "src/helpers/miscHelpers";

type RhFormErrors = {
  files?: string;
  comm?: string;
  email?: string;
};
export const RhService = () => {
  const defaultErrorsValue: RhFormErrors = {
    files: undefined,
    comm: undefined,
  };
  const [comm, setComm] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [files, setFiles] = useState<Array<any>>();
  const [errors, setErrors] = useState<RhFormErrors>(defaultErrorsValue);
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();
  const { api, toast, isOnline } = useContext(AppContext);
  const getFiles = (e): void => {
    setFiles(e);
  };
  const validateForm = () => {
    let newErrors: RhFormErrors = defaultErrorsValue;

    let valid = true;
    if (!comm || comm === "") {
      newErrors.comm = "Veuillez expliquez votre situation en écrivant un commentaire svp.";
      valid = false;
    }
    if (!files || files.length === 0) {
      newErrors.files = "Veuillez nous partager entre 1 et 3 justificatif(s) svp";
      valid = false;
    }
    if(!email){
      newErrors.email = "Veuillez insérer votre email personnel"
      valid = false;
    }else if(!validateEmail(email)){
      newErrors.email = "Veuillez entrer un mail valide"
      valid = false;
    }
    setErrors({ ...newErrors });
    return valid;
  };
  const onSubmit = (): void => {
    if (!validateForm()) return;
    setShowModal(true);
    api.user
      .sendCertificat({ myMail: email, message: comm, file: files })
      .then((_) => {
        setShowModal(false);
        history.push(routes.serviceRhConfirmation);
      })
      .catch((_) => {
        setShowModal(false);
        toast.createUnknkowError();
      });
  };

  return (
    <Page pageClass="rhService">
      {!isOnline && <OfflineLock />}
      <PageHeader back={routes.health} title="Déclaration d'absence" />
      <Container>
        <UploadField
          label="Ajouter un(des) justificatif(s)"
          onSelect={getFiles}
          error={errors.files}
          max={3}
        />
        <div className="separator"></div>
        <Text type="h4">Message</Text>
        <Textarea name="Commentaire" value={comm} setValue={setComm} error={errors.comm} />
        <div className="rhService__email-label">
          <Text type="p2">Afin de faciliter les échanges sur votre dossier, merci de nous communiquer une adresse mail privée.</Text>
        </div>
        <Input type="email" name="email" label="Mon e-mail personnel" value={email} setValue={setEmail} error={errors.email} />
        <Button large onClick={onSubmit}>
          Envoyer
        </Button>
      </Container>
      <LoadingScreen show={showModal} message="Uploading des fichiers en cours" backdrop />
    </Page>
  );
};
